<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>内容管理</a-breadcrumb-item>
    <a-breadcrumb-item>片库</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <!-- 剧名： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">剧名：</a-col>
          <a-col :span="18">
            <a-input v-model:value="nameModel" class="tool-item" placeholder="请输入" />
          </a-col>
        </a-row>
      </a-col>
      <!-- 剧情分类： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">剧情分类：</a-col>
          <a-col :span="18">
            <a-select v-model:value="plotType" class="tool-item" placeholder="请选择">
              <a-select-option v-for="item in PlotTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button style="margin-left: 16px" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 剧名 -->
      <template v-if="column.key === 'name'">
        <img class="cover-img" :src="$pub.CDN_URL(record.s_cover && record.s_cover[0] && record.s_cover[0].url)">
        <span class="cover-title">{{ record.remark_name }}</span>
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchDramaInfo(record)">剧集信息</a>
        <a class="operation-item" @click="touchPreview(record)">预览</a>
      </template>
    </template>
  </a-table>
  <!-- 预览 -->
  <PreviewVideoProModal ref="RefPreviewModal"></PreviewVideoProModal>
  <!-- 剧集详情 -->
  <DramaInfo ref="RefDramaInfo"></DramaInfo>
</template>

<script setup>
import { onBeforeMount, reactive, ref } from 'vue'
import { message } from 'ant-design-vue'
import { dramaList } from '@/api/promotion'
import { PlotTypes } from '@/utils/constantList'
import PreviewVideoProModal from '@/components/preview/PreviewVideoProModal'
import DramaInfo from './components-film-library/DramaInfo'

// 视频预览
let RefPreviewModal = ref(null)
// 剧集详情
let RefDramaInfo = ref(null)
// 剧名
let nameModel = ref(undefined)
// 剧名
let name = ref(undefined)
// 剧情分类
let plotType = ref(undefined)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '剧名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '剧集ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '总集数',
    dataIndex: 'total_ep',
    key: 'total_ep'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

onBeforeMount (() => {
  // 初始化获取
  touchSearch()
})

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  // 获取列表
  getDramaList()
}

// 查询
function touchSearch () {
  // 调整参数
  name.value = nameModel.value
  pagination.current = 1
  // 获取列表
  getDramaList()
}

// 重置
function touchReset () {
  nameModel.value = undefined
  name.value = undefined
  plotType.value = undefined
  // 初始化获取
  touchSearch()
}

// 预览
function touchPreview (record) {
  RefPreviewModal.value.showModal(record.drama_id, 1, record.agent_drama_mapping.is_download)
}

function touchDramaInfo (record) {
  RefDramaInfo.value.showDrawer(record)
}

// 获取列表
function getDramaList () {
  isLoading.value = true
  const params = {
    name: name.value,
    male_and_female: plotType.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  dramaList(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    }
  }).catch(() => {
    isLoading.value = false
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

.cover-img {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.cover-title {
  margin-left: 10px;
}
</style>